import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";

/**
 * Service to call HTTP request via Axios
 */

//////////////
// var protocol = location.protocol
// var hostname = location.hostname 
// var port = location.port
//////////////

// var baseApi = "http://192.168.18.97:3000"
// var baseApi = location.protocol + "//notasi.user.cloudjkt03.com"
// var baseApi = location.protocol + "//54.255.67.228"

var baseApi = "https://demo.notasis.id"
// var baseUpload = "http://192.168.18.115:3002"

// var baseApi = "https://kelaswara.notasis.id"
var baseUpload = "https://notasis-kelaswara.s3.ap-southeast-1.amazonaws.com"

// var baseApi = protocol + '//' + hostname + ":3000"
// var baseApiPdf = "http://103.154.90.190" //local kantor

/////////
var baseApiAsiate = "https://saas.asiatechintiraya.com" //http://192.168.18.115:3000 //https://saas.asiatechintiraya.com
var baseApiPdfAsiate = baseApiAsiate

var apiRealAsiate = baseApiAsiate + "/saasapi"
var apiPdfAsiate = baseApiPdfAsiate + "/pdfviewer/"
const baseUrlAsiate =  apiRealAsiate
////////

var baseApiPdf = baseApi

///*
// var cloudServerList = [
  // {hostname_be: '192.168.18.83', port: '', hostname_pdf: '192.168.18.83', port_pdf: '', name: 'internal_server'}, 
  // {hostname_be: '25.18.203.74', port: '', hostname_pdf: '25.18.203.74', port_pdf: '', name: 'internal_server_hamachi'},
  // {hostname_be: 'elibsekau.user.cloudjkt03.com', port: '', hostname_pdf: 'elibsekau.user.cloudjkt03.com', port_pdf: '', name: 'dewacloud_server'},
// ]

// var objCloudServer = cloudServerList.find(({ hostname_be }) => hostname_be == hostname);
// var objCloudServer = cloudServerList.findFirst(hostname, 'hostname')
// if(objCloudServer != undefined){
//   baseApi = protocol + '//' + objCloudServer.hostname_be + objCloudServer.port
//   baseApiPdf = protocol + '//' + objCloudServer.hostname_pdf + objCloudServer.port_pdf
//}
//*/

var apiReal = baseApi + "/api"
var apiPdf = baseApiPdf + "/pdfviewer/"
const baseUrl =  apiReal

var instance_notasi = null
var instance_asiate = null

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    // Vue.axios.defaults.baseURL = this.getBaseUrl() //"http://localhost";
    // Vue.axios.defaults.headers = {"Content-Type": "application/x-www-form-urlencoded" , "Access-Control-Allow-Origin": "*"}
    // Vue.axios.defaults.headers = {"Content-Type": "multipart/form-data" , "Access-Control-Allow-Origin": "*"}

    instance_notasi = Vue.axios.create({
      baseURL: this.getBaseUrl(),
      headers: {"Content-Type": "multipart/form-data" , "Access-Control-Allow-Origin": "*"},
    })
    // instance_notasi
    instance_asiate = Vue.axios.create({
      baseURL: this.getBaseUrl('asiate'),
      headers: {"Content-Type": "multipart/form-data" , "Access-Control-Allow-Origin": "*"}
    })
    // instane_asiate
  },

  getBaseUrlUpload(){
    var result = baseUpload
    return result
  },

  getBaseUrl(con){
    var result = baseUrl
    if(con == 'asiate'){
      result = baseUrlAsiate
    }
    return result
  },

  getBaseUrlPdf(con){
    var result = apiPdf
    if(con == 'asiate'){
      result = apiPdfAsiate
    }
    return result
  },

  setBaseUrl(url){
    baseApi = url 
    return baseApi
  },
  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Token ${JwtService.getToken()}`;
  },

  // query(resource, params) {
  //   return Vue.axios.get(resource, params).catch(error => {
  //     // console.log(error);
  //     throw new Error(`[KT] ApiService ${error}`);
  //   });
  // },

  // /**
  //  * Send the GET HTTP request
  //  * @param resource
  //  * @param slug
  //  * @returns {*}
  //  */
  // get(resource, slug = "") {
  //   return Vue.axios.get(`${resource}/${slug}`).catch(error => {
  //     // console.log(error);
  //     throw new Error(`[KT] ApiService ${error}`);
  //   });
  // },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return instance_notasi.post(`${resource}`, params)
    // return Vue.axios.post(`${resource}`, params);
  },

  post2(resource, params) {
    return instance_asiate.post(`${resource}`, params)
    // return Vue.axios.post(`${resource}`, params);
  },

  // /**
  //  * Send the UPDATE HTTP request
  //  * @param resource
  //  * @param slug
  //  * @param params
  //  * @returns {IDBRequest<IDBValidKey> | Promise<void>}
  //  */
  // update(resource, slug, params) {
  //   return Vue.axios.put(`${resource}/${slug}`, params);
  // },

  // /**
  //  * Send the PUT HTTP request
  //  * @param resource
  //  * @param params
  //  * @returns {IDBRequest<IDBValidKey> | Promise<void>}
  //  */
  // put(resource, params) {
  //   return Vue.axios.put(`${resource}`, params);
  // },

  // /**
  //  * Send the DELETE HTTP request
  //  * @param resource
  //  * @returns {*}
  //  */
  // delete(resource) {
  //   return Vue.axios.delete(resource).catch(error => {
  //     // console.log(error);
  //     throw new Error(`[RWV] ApiService ${error}`);
  //   });
  // }
};

export default ApiService;
