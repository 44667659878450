import router from "../../../router";
import store from "../../../core/services/store";
import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: 'top-right',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

function getRes(response){
  var newRess = {
      status: response.status,
      data: response.data,
      error: '',
      message: response.statusText,
      message_opt: '',
      total: response.total,
  }

  if(response.status == 200){
    if(response.data.status == 3001 || response.data.status == 3007){
      store.dispatch('logout')
      router.push({ name: "login" })
    }else if(response.data.status == 3002){
      Toast.fire({
        icon: 'error',
        title: 'Access Denied!'
      })
    }
    newRess.status= response.data.status
    newRess.data= response.data.data
    newRess.error= response.data.error
    newRess.message= response.data.statusText
    newRess.message_opt= response.data.message_opt
    newRess.total = response.data.total
  }else{
    //gak tau mau apa
  }

  return newRess
}

function objToFormData(itemObj){
  var form_data = new FormData();

  for ( var key in itemObj ) {
    let val = itemObj[key]
    if(val === null)
      val = ''

    if(typeof itemObj[key] === 'object' && itemObj[key] !== null
        && itemObj[key].constructor !== File && itemObj[key].constructor !== Blob){
          form_data.append(key, JSON.stringify(val));
    }else{
      form_data.append(key, val);
    }
  }

  return form_data
}

export default class{
    static PostData(BaseApi, url, objData, onSuccess, onError) {
        let szData = objToFormData(objData)
        return BaseApi.post(url, szData)
            .then(data => onSuccess(getRes(data)))
            .catch(err => onError(err));
    }

    static PostData2(BaseApi, url, objData, onSuccess, onError) {
      let szData = objToFormData(objData)
      return BaseApi.post2(url, szData)
          .then(data => onSuccess(getRes(data)))
          .catch(err => onError(err));
  }
}
