import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import Services from "@/core/services/notasis-api/Services";
import storagelocal from "@/core/services/storagelocal.service";
var CryptoJS = require("crypto-js");

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
import Swal from "sweetalert2";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken()
};

export const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

// function getMessage(){
//   //keluarin message kaya success/ gagal insert, atau berhasil simpan, berhasil hapus
// }

function loginWithUrl(context,user,pass){
  return new Promise(resolve => {
    var mydata = {}
    mydata.Trigger         = 'R',
    mydata.username        = user,
    mydata.password        = pass,
    mydata.Route           = 'login',
    mydata.level_comparing = '=',
    mydata.level           = '0'
        
    Services.PostData(ApiService, "users", mydata, response=>{
      if(response.status == 1000){
        context.commit(SET_AUTH, response.data);
        storagelocal.setLocalStorage('username', response.data[0].name)
        storagelocal.setLocalStorage('UID', response.data[0].id)
        storagelocal.setLocalStorage('token', response.data[0].token)
        storagelocal.setLocalStorage('email', response.data[0].email)
        storagelocal.setLocalStorage('jabatan', response.data[0].jabatan)
        storagelocal.setLocalStorage('no_hp', response.data[0].no_hp)
        storagelocal.setLocalStorage('level', response.data[0].level)
        // storagelocal.setLocalStorage('role', response.data[0].role_id)

        // var audiences_list = []
        // for(var i=0;i<response.data[0].audiences_list.length;i++){
        //   audiences_list.push(response.data[0].audiences_list[i].audience_id)
        // }
        // storagelocal.setLocalStorage('audiences_list', audiences_list)

        if(response.data[0].path_file != null){
          storagelocal.setLocalStorage('photo_conv', response.data[0].path_file)
        }else{
          storagelocal.setLocalStorage('photo_conv', '')
        }
      }else{
        context.commit(SET_ERROR, []);
        Swal.fire({
            title: "",
            text: "Gagal Login",
            icon: "error",   
            heightAuto: false
        });
      }
      resolve(response.data);
    }, err =>{
      context.commit(SET_ERROR, err);
    })
  });
}

// function converterDateBe(date){
//   var conv_date = new Date(date);
//   var dd = String(conv_date.getDate()).padStart(2, '0');
//   var mm = String(conv_date.getMonth() + 1).padStart(2, '0'); 
//   var yyyy = conv_date.getFullYear();

//   return yyyy + '-' + mm + '-' + dd;
// }

const actions = {
  [LOGIN](context, credentials) {
    var app_code_split = credentials.email.split("-")
    return new Promise(resolve => {
      var mydata = {
        Trigger             : 'R',
        Route               : 'get_url',
        app_code            :  app_code_split[0]
      }
      Services.PostData2(ApiService, "profile", mydata, async response=>{
        if(response.status == 1000){
          // var mydata = {}
          // mydata.IsUpdateSaas = 1
          // mydata.url_saas = response.data[0].url 
          // mydata.name_saas = response.data[0].name 
          // mydata.address_saas = response.data[0].address 
          // mydata.email_saas = response.data[0].email 
          // mydata.no_hp_saas = response.data[0].no_hp 
          // mydata.path_file_saas = response.data[0].path_file 
          // mydata.join_date_saas = converterDateBe(response.data[0].join_date) 
          // mydata.range_activation_day_saas = response.data[0].range_activation_day 
          // mydata.start_activation_date_saas = converterDateBe(response.data[0].start_activation_date) 
          // mydata.exp_activation_date_saas = converterDateBe(response.data[0].exp_activation_date)
          // mydata.grace_period_date_saas = converterDateBe(response.data[0].grace_period_date) 
          // mydata.activation_amount_saas = response.data[0].activation_amount 
          // mydata.is_activated_saas = response.data[0].is_activated 
          // mydata.limit_users_saas = response.data[0].limit_users
          ApiService.setBaseUrl(response.data[0].protocol + response.data[0].url)
          await loginWithUrl(context, app_code_split[1], CryptoJS.SHA256(credentials.password).toString())
        }else{
          Swal.fire({
              title: "Failed",
              text: "Gagal Login",
              icon: "error",   
              heightAuto: false
          });
        }
        resolve(response.data);
      }, err =>{
        context.commit(SET_ERROR, err);
      })
    });
  },
  // [LOGIN](context, credentials) {
  //   return new Promise(resolve => {
  //     var mydata = {
  //       Trigger             : 'R',
  //       username            : credentials.email,
  //       password            : CryptoJS.SHA256(credentials.password).toString(),
  //       Route               : 'login',
  //       level_comparing     : '=',
  //       level               : '0'
  //     }
  //     Services.PostData(ApiService, "users", mydata, response=>{
  //       if(response.status == 1000){
  //         context.commit(SET_AUTH, response.data);
  //         storagelocal.setLocalStorage('username', response.data[0].name)
  //         storagelocal.setLocalStorage('UID', response.data[0].id)
  //         storagelocal.setLocalStorage('token', response.data[0].token)
  //         storagelocal.setLocalStorage('email', response.data[0].email)
  //         storagelocal.setLocalStorage('jabatan', response.data[0].jabatan)
  //         storagelocal.setLocalStorage('no_hp', response.data[0].no_hp)
  //         storagelocal.setLocalStorage('level', response.data[0].level)
  //         // storagelocal.setLocalStorage('role', response.data[0].role_id)

  //         // var audiences_list = []
  //         // for(var i=0;i<response.data[0].audiences_list.length;i++){
  //         //   audiences_list.push(response.data[0].audiences_list[i].audience_id)
  //         // }
  //         // storagelocal.setLocalStorage('audiences_list', audiences_list)

  //         if(response.data[0].path_file != null){
  //           storagelocal.setLocalStorage('photo_conv', response.data[0].path_file)
  //         }else{
  //           storagelocal.setLocalStorage('photo_conv', '')
  //         }
  //       }else{
  //         context.commit(SET_ERROR, []);
  //         Swal.fire({
  //             title: "",
  //             text: "Gagal Login",
  //             icon: "error",   
  //             heightAuto: false
  //         });
  //       }
  //       resolve(response.data);
  //     }, err =>{
  //       context.commit(SET_ERROR, err);
  //     })
  //   });
  // },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
    storagelocal.setLocalStorage('username', null)
    storagelocal.setLocalStorage('UID', null)
    storagelocal.setLocalStorage('token', null)
    storagelocal.setLocalStorage('role', null)
    storagelocal.setLocalStorage('audiences_list', [])
  },
  [REGISTER](context, credentials) {
    return new Promise(resolve => {
      var mydata = {
        Trigger: 'C',
        name: credentials.username,
        nrp: credentials.nrp,
        id_card: credentials.id_card,
        email: credentials.email,
        expired_at : credentials.expired_at,
        password: CryptoJS.SHA256(credentials.password).toString(),
        Route: 'public_registration',
        audiences_list: [1],
        is_include_audiences: 1,
        is_ignore_expired: 0,
        // role_id: 2,
        status_acc: 0,
      }
      Services.PostData(ApiService, "users", mydata, response=>{
        if(response.status == 1000){
          context.commit(SET_AUTH, response.status);
          Swal.fire({
            title: "",
            text: "Registrasi Berhasil",
            icon: "success",   
            heightAuto: false
          });
        }else{
          context.commit(SET_ERROR, []);
          Swal.fire({
              title: "",
              text: "Gagal Registrasi",
              icon: "error",   
              heightAuto: false
          });
        }
        resolve(response.data);
      }, err =>{
        context.commit(SET_ERROR, err);
      })
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      // ApiService.setHeader();
      if(context.state.user.data > 0){
        context.commit(SET_AUTH, context.state.user.data);
      }else{
        context.commit(SET_ERROR, context.state.user.error);
      }
      // ApiService.get("verify")
      //   .then(({ data }) => {
      //     context.commit(SET_AUTH, data);
      //   })
      //   .catch(({ response }) => {
      //     context.commit(SET_ERROR, response.data.errors);
      //   });

    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const email = payload;
    return new Promise(resolve => {
      var mydata = {
        Trigger: 'U',
        email: email.email,
        Route: 'forgot_password'
      }
      Services.PostData(ApiService, "users", mydata, response=>{
        if(response.status == 1000){
          Swal.fire({
            title: "",
            text: "Cek Email Anda",
            icon: "success",   
            heightAuto: false
          });
        }else{
          Swal.fire({
              title: "",
              text: "Gagal Kirim Link",
              icon: "error",   
              heightAuto: false
          });
        }
        resolve(response.data);
      }, err =>{
        context.commit(SET_ERROR, err);
      })
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user[0].name;
    state.errors = {};
    JwtService.saveToken(state.user[0].remember_token);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
