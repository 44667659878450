
export const setLocalStorage = (prop, data) => {
  localStorage.setItem(prop, data)
};

export const getLocalStorage = (prop) => {
  return localStorage.getItem(prop);
};
  

export default { getLocalStorage, setLocalStorage };
